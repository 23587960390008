(function ($, window, document) {

    $(function () {
        var os = '';
        var device = ' desktop';
        var mobileDevice = false;

        if (navigator.userAgent.match(/iPhone|iPad|iPod|Android|BlackBerry|IEMobile/)) {
            device = ' mobile';
        }

        if (navigator.platform.toLowerCase().indexOf('mac') !== -1) {
            os = 'mac';
        } else if (navigator.platform.toLowerCase().indexOf('win') !== -1) {
            os = 'win';
        } else if (navigator.platform.toLowerCase().indexOf('linux') !== -1) {
            os = 'linux';
        }

        $('html').addClass(os + device);
    });

}(window.jQuery, window, document));
